<template>
  <modal
    :modalId="`cpSolicitaMeta`"
    :modalTitle="'Solicitação Alteração de Meta'"
    :load="load"
    :typeLg="`modal_md`"
    @closeModal="closeModal"
  >
    <ValidationObserver tag="form" ref="formNewGoal" class="col-md-12 px-0">
      <div class="row m-auto">
        <div class="col-md-6 mb-1 form-group form-outline">
          <label class="mb-1 label_default">Meta atual</label>
          <input
            type="text"
            class="form-control input_default"
            :value="valueConvertBR(goalCurrent)"
            disabled
          />
        </div>
        <ValidationProvider
          tag="div"
          class="col-md-6 mb-1 form-group form-outline"
          v-slot="{ errors, ariaMsg, classes }"
          :rules="{
            required: true,
            max: 12,
            validaValue: true,
            validaValueZero: true
          }"
          name="Nova Meta"
        >
          <label class="mb-1 label_default">Nova Meta</label>
          <input
            type="text"
            class="form-control input_default"
            v-model="newGoal"
            :class="classes"
            @keypress="checkValueOnly($event)"
            @blur="newGoal = valueConvertBR(parseFloat($event.target.value))"
          />
          <span v-bind="ariaMsg" :class="classes">{{ errors[0] }}</span>
        </ValidationProvider>
      </div>
      <label class="label_info row col-md-10 m-auto">
        O Valor solicitado só será alterado à partir do próximo mês
      </label>
      <actions
        :disabled.sync="disabled"
        @closeModal="closeModal"
        @sendGoal="sendGoal"
        ref="actions"
      />
    </ValidationObserver>
  </modal>
</template>

<script>
import { extend } from "vee-validate";
import modal from "@/components/modal/modalDefault.vue";
import actions from "./acoes/botoes_rodape.vue";
export default {
  props: ["goalCurrent"],
  components: {
    modal,
    actions
  },
  data() {
    return {
      load: true,
      disabled: false,
      obj: {
        newGoal: ""
      }
    };
  },
  mounted() {
    this.load = false;
    this.registerValidations();
  },
  methods: {
    sendGoal() {
      this.$refs.formNewGoal.validate().then(success => {
        if (success) {
          this.disabled = true;
          this.newGoal = this.newGoal.replace(".", "").replace(",", ".");
          this.$store
            .dispatch("InsertSolicityGoal", this.newGoal)
            .then(result => {
              this.toast(
                "Solicitacao encaminhada com exito.",
                this.$toast.success
              );
              this.disabled = false;
              this.closeModal();
            })
            .catch(error => {
              this.toast("Ops, ocorreu algo de errado", this.$toast.error);
              this.disabled = false;
            });
        }
      });
    },
    closeModal() {
      this.$emit("closeModal");
    },
    registerValidations() {
      extend("validaValue", {
        validate: this.validaValue,
        message: (field, params, data) => {
          return "Não é possivel solicitar um valor que ja está aplicado.";
        }
      });
      extend("validaValueZero", {
        validate: this.validaValueZero,
        message: (field, params, data) => {
          return "Não é possivel solicitar valor de R$ 0,00";
        }
      });
    },
    validaValue() {
      return !(
        this.newGoal.toString().replace(",", "") ==
        this.goalCurrent.toString().replace(",", "")
      );
    },
    validaValueZero() {
      return !(this.newGoal == "0,00");
    }
  }
};
</script>

<style scoped src="@/assets/style/modal.css"></style>
<style scoped>
.label_info {
  font-family: Poppins;
  font-size: 11px;
}
</style>
