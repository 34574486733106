<template>
  <div class="containerChange">
    <div class="d-flex mr-3 align-items-center" id="nameUnityCont">
      <h3 class="mr-2">{{ $store.getters.tenantRequire.tenancyName }}</h3>
      <div class="dropdown" v-if="items.length > 1">
        <button
          class="btn p-1 d-flex align-items-center justify-content-around"
          type="button"
          id="dropdownMenuButton"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          <img src="@/assets/image/metas/change.svg" alt="change" />
          <img src="@/assets/image/metas/iconPF.svg" alt="iconPF" />
        </button>
        <div
          class="dropdown-menu containerMenu pb-0"
          aria-labelledby="dropdownMenuButton"
        >
          <div class="headerMenu">
            <h2 class="mr-2 p-0">Trocar Unidade</h2>
          </div>
          <div
            :class="
              $store.getters.tenantRequire.tenancyName === item.tenanancyName
                ? 'main bgcp'
                : 'main'
            "
            v-for="item in items"
            :key="item.id"
            @click.prevent="enterTenant(item)"
          >
            <h2>{{ item.tenanancyName }}</h2>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HTTP from "@/api_system";
export default {
  data() {
    return {
      items: []
    };
  },
  mounted() {
    this.getUnidades();
  },
  methods: {
    getUnidades() {
      HTTP.get("services/app/User/GetMyTenants", {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then(response => {
          this.items = response.data.result;
        })
        .catch(error => {
          console.log(error);
        });
    },
    enterTenant(item) {
      if (
        this.$store.getters.tenantRequire.tenancyName === item.tenanancyName
      ) {
        this.toast(
          `Unidade ${item.tenanancyName} já selecionada`,
          this.$toast.warning
        );
        return;
      }

      HTTP.post(
        `TokenAuth/AuthenticateFromMaster?tenantId=${item.tenantId}&userId=${item.userId}`,
        "",
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      )
        .then(response => {
          this.$nextTick(() => {
            this.$store.commit("set_token", response.data.result.accessToken);
            this.$store.commit("set_user", response.data.result.informations);
            this.$store.commit("set_tenant", response.data.result.tenant);
            this.$emit("reloadCP");
            this.toast(
              `Unidade ${item.tenanancyName} selecionada`,
              this.$toast
            );
          });
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>

<style scoped>
.containerChange h3 {
  font-weight: 400;
  font-size: 16px;
  color: #98c4e6;
  padding: 0;
  margin: 0;
}

.containerChange button {
  width: 75px;
  height: 43px;
  border: 2px solid #3f88c1;
  border-radius: 6px;
}

.headerMenu {
  border-bottom: 1px solid #3f89c1bb;
  padding: 0 10px 0 10px;
}

.headerMenu h2 {
  font-weight: 500;
  font-size: 19px;
  color: #2474b2;
}

.containerMenu {
  min-width: 210px;
  border-radius: 5px;
}

.main h2 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  padding: 10px 10px 0 10px;
}

.main {
  height: 40px;
  text-align: left;
  cursor: pointer;
  color: #2474b2;
}

.main:hover {
  background: #f38235;
  color: #fff;
}

.bgcp {
  background: #86868650;
  color: #2474b2;
  cursor: not-allowed;
}
@media screen and (max-width:767px){
  .containerChange h3{
    margin-right: 5.5rem!important;
    font-size: 18px;
  }
  .containerMenu {
    min-width: 200px;
  }
}
@media screen and (max-height:680px){
  .containerChange h3{
    margin-right: 2.5rem!important;
  }
}

@media screen and (max-width:500px){
  .containerChange h3{
    margin-right: 5.5rem!important;
  }
}
@media screen and (max-width:420px){
  .containerChange h3{
    margin-right: 4.5rem!important;
  }
}
@media screen and (max-width:375px){
  .containerChange h3{
    margin-right: 3.5rem!important;
  }
}
@media screen and (max-width:360px){
  .containerChange h3{
    margin-right: 2.5rem!important;
  }
}
</style>
