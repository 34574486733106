<template>
  <div class="col-md-12 mt-1">
    <div
      class="d-flex align-items-center justify-content-center m-5"
      style="height:auto;"
      v-if="load"
    >
      <half-circle-spinner
        :animation-duration="1000"
        :size="100"
        color="#fff"
      />
    </div>
    <div class="col-xl-12 col-md-10 element_div pb-3 px-0">
      <transition name="fade">
        <div v-if="this.sizeWindow > 767">
          <div v-show="!load" class="flex mb-3 containerButton">
            <p class="titulo_dash mr-5 mt-0 classText">Metas da sua Equipe</p>
            <div class="d-flex align-items-center justify-content-between">
              <ChangeStore @reloadCP="reloadCP" />
              <ButtonToggle @toggleConvert="toggleBtn" :activo="activoCurrent" />
            </div>
          </div>
        </div>
        <div v-if="this.sizeWindow < 768">
          <div v-show="!load" class="flex mb-3 containerButton">
            <div class="d-flex align-items-center justify-content-between">
              <ChangeStore @reloadCP="reloadCP" />
              <ButtonToggle @toggleConvert="toggleBtn" :activo="activoCurrent" />
            </div>
            <p class="titulo_dash mr-5 mt-0 classText">Metas da sua Equipe</p>
          </div>
        </div>
      </transition>
      <transition name="fade">
        <div class="container ml-0" v-show="!load">
          <div class="row borderBottom" v-if="this.sizeWindow > 767">
            <div class="col-4 p-0 ">
              <h3 class="subText ">Meta da loja</h3>
            </div>
            <div class="col">
              <h3 class="subText">Vendedores em Destaque</h3>
            </div>
            <div class="col">
              <router-link to="/unidades" tag="div" v-if="pessoas.length >= 3">
                <h3 class="text-right allSubText">
                  Todos Vendedores
                  <img src="@/assets/image/metas/arrow.svg" alt="arrow.svg" />
                </h3>
              </router-link>
            </div>
          </div>
        </div>
      </transition>
      <div class="container ml-0" v-if="this.sizeWindow > 767">
        <div class="row">
          <transition name="fade">
            <total
              class="itens list-complete-item col"
              :key="1"
              v-show="!load"
              :porceto="activoCurrent"
            />
          </transition>
          <hr class="p-0 hr col-auto" v-show="!load" />
          <div :class="'d-flex col p-0 containerMetrica'">
            <metrica
              v-for="(item, index) in pessoas"
              :key="index + 2"
              :item="item"
              class="itens list-complete-item"
              :porceto="activoCurrent"
              @openModalGoalUser="openModalGoalUser"
            />
          </div>
        </div>
        <div v-if="images.length > 0 && this.sizeWindow > 767" class="mt-5 col-12">
          <VueSlickCarousel :arrows="true" v-bind="settings">
            <div
              v-for="item in images"
              :key="item.id"
              class="d-flex justify-content-center"
            >
              <a :href="item.link" target="_blank">
                <img
                    class="mx-auto"
                    :src="getImg(item.image)"
                    :alt="`${item.image}`"
                  />
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
      <div class="container ml-0" v-if="this.sizeWindow < 768" id="contGraphics">
        <div class="mobileGraphics">
          <div class="col-4 p-0 ">
            <h3 class="subText ">Meta da loja</h3>
          </div>
          <transition name="fade">
            <total
              class="itens list-complete-item col"
              :key="1"
              v-show="!load"
              :porceto="activoCurrent"
            />
          </transition>
            <div class="col" id="vendTextCont">
              <h3 class="subText" id="destText">Vendedores em Destaque</h3>
            </div>
          <div :class="'d-flex col p-0 containerMetrica'">
            <metrica
              v-for="(item, index) in pessoas"
              :key="index + 2"
              :item="item"
              class="itens list-complete-item"
              :porceto="activoCurrent"
              @openModalGoalUser="openModalGoalUser"
            />
          </div>
          <div class="col" id="allVendCont">
              <router-link to="/unidades" tag="div" v-if="pessoas.length >= 3">
                <h3 class="text-right allSubText">
                  Todos Vendedores
                  <img src="@/assets/image/metas/arrow.svg" alt="arrow.svg" />
                </h3>
              </router-link>
            </div>
        </div>
        <div v-if="images.length > 0 && this.sizeWindow > 767" class="mt-5 col-12">
          <VueSlickCarousel :arrows="true" v-bind="settings">
            <div
              v-for="item in images"
              :key="item.id"
              class="d-flex justify-content-center"
            >
              <a :href="item.link">
                <img
                    class="mx-auto"
                    :src="getImg(item.image)"
                    :alt="`${item.image}`"
                  />
              </a>
            </div>
          </VueSlickCarousel>
        </div>
      </div>
    </div>
    <div class="col-xl-12 col-md-10 px-0" id="yourIndicators">
      <transition name="fade">
        <p
          class="titulo_dash mb-0 my-3 classText borderBottom pb-3"
          v-show="!load"
        >
          Seus indicadores
        </p>
      </transition>
      <transition-group name="list-complete" tag="div" class="row">
        <indicadores
          v-for="(item, index) in indicadores"
          :key="index + 1"
          :item="item"
          class="list-complete-item"
        />
      </transition-group>
    </div>
    <modalGoalUser v-if="salesman != null" :salesman="salesman" @sendUser="sendUser" @closeModalGoalUser="closeModalGoalUser"/>
    <modalDegustacao/>
  </div>
</template>

<script>
import HTTP from '@/api_system'
import metrica from "../metas/Franqueado/_Metas_mensais_funcionario";
import total from "../metas/Franqueado/_Total_metas";
import indicadores from "../indicadores/_Indicadores";
import { HalfCircleSpinner } from "epic-spinners";
import ButtonToggle from "../../components/metas/buttons/button_toggle.vue";
import { Carousel, Slide } from "vue-carousel";
import ChangeStore from "../../components/metas/Franqueado/_ChangeStore.vue";
import modalGoalUser from '../metas/Franqueado/modal/ModalGoalUser.vue';
import modalDegustacao from "@/components/modal/modalDegustacao";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

export default {
  components: {
    metrica,
    total,
    indicadores,
    ButtonToggle,
    HalfCircleSpinner,
    Carousel,
    Slide,
    ChangeStore,
    VueSlickCarousel,
    modalGoalUser,
    modalDegustacao
  },
  data() {
    return {
      salesman: {},
      indicadores: [],
      pessoas: [],
      sizeWindow: 0,
      load: true,
      showCp: false,
      activoCurrent: "Percent",
      nextLabel:
        '<img src="@/assets/image/metas/arrowCarrocel.svg" alt="arrowCarrocel.svg">',
      settings: {
        infinite: true,
        speed: 500,
        autoplay: true,
        dots: false,
        adaptiveHeight: true      
      },
      images: [
        // {
        //   id: 1,
        //   image: "banner1.png",
        //   link: "https://horadecodar.com.br/2021/07/23/como-desfazer-um-git-merge-no-repositorio-local/"
        // },
        // {
        //   id: 2,
        //   image: "banner2.png",
        //   link: "https://horadecodar.com.br/2021/07/23/como-desfazer-um-git-merge-no-repositorio-local/"
        // }
      ],
    };
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.getList();
    this.$store.dispatch("getSuporte");
    this.getAll();
  },
  methods: {
    reloadCP() {
      this.$store.dispatch("getSuporte");
      this.getAll();
    },
    async getAll() {
      try {
        this.$store.dispatch("getFranqueadoInfo");
        const resolve = await this.$store.dispatch("GetIndicatorByMonth");
        this.indicadores = resolve;

        const result = await this.$store.dispatch("GetScoreOfSalesman");
        const filter = result.filter((item, index) => index <= 2);
        this.pessoas = filter;

        this.$nextTick(() => {
          this.load = false;
        });
      } catch (error) {
        console.log(error);
        this.errorFunction();
      }
    },
    errorFunction() {
      this.$notify({
        group: "erros",
        type: "error",
        text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
      });
    },
    toggleBtn(changeValue) {
      this.activoCurrent = changeValue;
    },
    openModalGoalUser(item) {
      this.salesman = item;
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");;
      } else {
        $('#modalGoalUser').modal('show');
      }
    },
    closeModalGoalUser() {
      $('#modalGoalUser').modal('hide');
      this.getAll();
    },
    sendUser(meta) {      
      if (meta > this.$store.getters.tenantGoal) {
        this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>A meta do vendedor não pode ser maior que a meta da unidade.`,
          });
      } else {
        this.load = true;
        var objPost = {
          id: this.salesman.dashboardUser.userId,
          displayName: this.salesman.dashboardUser.userDisplayName,
          name: this.salesman.dashboardUser.userName,
          email: this.salesman.dashboardUser.userEmail,
          photo: this.salesman.dashboardUser.userPhoto,
          cpf: this.salesman.dashboardUser.userCpf.toString(),
          profile: this.salesman.dashboardUser.userProfile,
          cellPhone: this.salesman.dashboardUser.userCellPhone,
          genre: parseInt(this.salesman.dashboardUser.userGenre),
          goal: meta
        };
        this.$store.dispatch("editCurrentUser", objPost).then((resolve) => {
          if (resolve.success) {
            this.$notify({
              group: "erros",
              type: "sucess",
              text: `<i class="icon ion-close-circled"></i>${resolve.msg}`,
            });
            this.reloadCP();
            this.getUsers();
          } else {
            this.$notify({
              group: "erros",
              type: "error",
              text: `<i class="icon ion-close-circled"></i>${resolve.msg}`,
            });
          }
        });
        this.closeModalGoalUser();
        this.load = true;
      }
    },
    getList () {
      let user = this.$store.getters.getUser;
      HTTP.get(`services/app/Notice/GetDashBoardNotices`, {
        headers: { Authorization: `Bearer ${this.$store.getters.getterToken}` }
      })
        .then((response) => {
          let res = response.data.result
          let imgs = [];
          res.forEach((item) => {
            if ((item.profile == user.profile || item.profile == 0) && item.type == 1) {
              imgs.push(item)
            }
          })
          this.images = imgs;
        })
        .catch((err) => console.log(err))
    },
    async getUsers() {
      await HTTP.get(
        `services/app/User/GetSumUsersGoalByTenant?tenantId=${this.$store.getters.get_tenant.id}`,
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        }
      ).then(res => {
        if (res.data.result) {
          $('#messageGoal')[0].hidden = false
        } else {
          $('#messageGoal')[0].hidden = true
        }
      });
    }

    // scroll() {
    //   var elementW = $(".element_div").width();
    //   var valTotal = $(".itens").length + this.pessoas.length;

    //   var w = $(".itens").width() * valTotal;
    //   var valMin = elementW - w - 10 * this.pessoas.length - 2;

    //   Draggable.create(".over-scroll", {
    //     type: "x",
    //     throwProps: true,
    //     edgeResistance: 0.9,
    //     maxDuration: 0,
    //     bounds: { maxX: 0, minX: valMin }
    //   });
    // },
  },
  
};
</script>

<style scoped>
.metas_p {
  color: #ffffff;
}

.a_hrf {
  color: #ffffff;
}

.over-scroll {
  cursor: pointer !important;
}

.flex {
  display: flex;
  align-items: center;
}

.containerButton {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  border-bottom: 1px solid #3f88c1;
}

.classText {
  font-style: normal;
  font-weight: 700;
  font-size: 21px;
  line-height: 32px;
  color: #ffffff;
}

.subText {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  color: #ffffff;
}

.allSubText {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #98c4e6;
  cursor: pointer;
}

.borderBottom {
  border-bottom: 1px solid #3f88c1;
  margin-bottom: 10px;
}

.hr {
  height: 300px;
  background: #3f88c1;
  margin: 0;
  width: 2px;
  margin: 0 12px 0 0;
}

.btnArrow {
  background: red;
}

.containerMetrica {
  overflow: auto;
  /* min-height: 350px; */
}

::-webkit-scrollbar {
  height: 7px !important;
}

::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0);
}

::-webkit-scrollbar-thumb {
  background-color: #98c4e6;
  border-radius: 10px;
  border: none;
}

.containerBanner {
  margin: 0 auto;
  margin-top: 20px;
}

.image-arrow {
  padding-left: 20px;
  padding-right: 20px;
}

.mobileGraphics {
  display: flex;
  flex-direction: column;
}
@media screen and (max-width:767px){
  #yourIndicators{
    display: none;
  }
  .containerButton{
    flex-direction: column;
    margin-top: 7%;
  }
  .subText{
    margin-bottom: 1rem;
  }
  .classText{
    margin-top: 7%!important;
  }
  .containerMetrica{
    min-height: 320px;
  }
  #contGraphics{
    padding: 0;
  }
  #destText{
    margin-top: 6%;
  }
  #vendTextCont{
    padding-left: 0!important;
  }
  #allVendCont{
    margin-top: 3%;
  }
}
</style>
