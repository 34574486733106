<template>
  <div class="div_class col-auto itens px-0">
    <div class="bg_black h-100">
      <div class="containerBtn p-3">
        <!-- <button class="btnAdd" @click="showModal()">
          <img src="@/assets/image/metas/mais.svg" alt="mais.svg" />
        </button> -->
      </div>
      <div class="row align-items-center">
        <div class="col-md-7 mx-auto" id="gaugCont">
          <VueSvgGauge
            :start-angle="-176"
            :end-angle="184"
            :value="aroundMath(totalMetas)"
            :separator-step="20"
            :separatorThickness="0"
            :min="0"
            baseColor="#093F69"
            :max="100"
            :scale-interval="0"
            :gauge-color="[
              { offset: 0, color: '#FED990' },
              { offset: 50, color: '#E99637' },
              { offset: 100, color: '#F38235' }
            ]"
            :transition-duration="2500"
          >
            <div
              class="inner-text h-100 d-flex align-items-center justify-content-center"
            >
              <!-- :style="`color:${scoreColor(aroundMath(totalMetas))}`" -->
              <p
                class="mb-0"
                style="font-size: 25px;"
                v-if="porceto === 'Percent'"
                key="porcento"
              >
                <span>{{ aroundMathFixed(totalMetas) }}</span>
                <small>%</small>
              </p>

              <p class="mb-0" v-else-if="porceto === 'money'" key="reais">
                <span style="font-size: 25px;">{{
                  valueConvert(totalMetasReais)
                }}</span>
              </p>
            </div>
          </VueSvgGauge>
        </div>

        <div
          class="d-flex align-items-center justify-content-around mt-3 containerMath"
        >
          <div class="containerCircle d-flex align-items-center">
            <div class="circle mr-2"></div>
            <span>{{ aroundMathFixed(totalMetas) }}</span>
            <small>%</small>
          </div>
          <div class="containerCircle d-flex align-items-center">
            <div class="circle whiteCircle mr-2"></div>
            <span>{{ totalMetas > 100 ? 0 : roundFn( 100 - totalMetas, 1) }}</span>
            <small>%</small>
          </div>
        </div>

        <div class="col-md-10 mx-auto text-center storeValueAll">
          <p class="mb-3">
            Sua loja atingiu <strong>{{ aroundMath(totalMetas) }}%</strong>
            da meta total do mês.
            <!-- Você atingiu {{ aroundMath(totalMetas) }}% da meta total do mês. -->
          </p>
        </div>
      </div>
    </div>
    <component
      :is="cp"
      :goalCurrent="$store.state.login.tenantGoal"
      @closeModal="closeModal"
    />
  </div>
</template>

<script>
import modal from "@/components/solicitacao/franqueado/cpSolicitaMeta.vue";
export default {
  props: {
    porceto: {
      type: String,
      default: "Percent"
    }
  },
  components: { modal },
  data() {
    return {
      cp: ""
    };
  },
  methods: {
    showModal() {
      this.cp = "modal";
      this.$nextTick(() => {
        $("#cpSolicitaMeta").modal("show");
      });
    },
    closeModal() {
      $(`#cpSolicitaMeta`).modal("hide");
      this.cp = "";
    }
  },
  computed: {
    totalMetas() {
      return this.$store.state.login.userScore;
    },
    totalDiferencaTotal() {
      return this.$store.state.login.differenceScoreTotal;
    },
    totalMetasReais() {
      return this.$store.state.login.userOverallSold;
    }
  }
};
</script>

<style scoped>
small {
  font-size: 20px;
}
.div_class {
  /* height: 230px; */
  min-width: 250px;
  margin-right: 10px;
}
p {
  color: #ffff;
  font-size: 10px;
}

.bg_black {
  background: #072e4d;
  border: none;
  border-radius: 12px;
  border: 1px solid #3f88c1;
  width: 255px;
  min-height: 268px;
}

.inner-text p {
  font-weight: 700;
  font-size: 42px !important;
  line-height: 33px;
  text-align: center;
}

.textSpan {
  font-size: 25px;
}

.containerBtn {
  display: flex;
  justify-content: flex-end;
  padding: 10px 10px 0;
}

.btnAdd {
  background: none;
  border: none;
}

.circle {
  height: 13px;
  width: 13px;
  border-radius: 50%;
  background-image: linear-gradient(270deg, #e0a638 0%, #f38235 100%);
}

.whiteCircle {
  background: #093f69 !important;
}

.containerCircle span,
.containerCircle small {
  font-weight: 700;
  font-size: 12px;
  color: #2474b2 !important;
}

.containerMath {
  border-bottom: 2px solid #2474b2;
  width: 80%;
  margin: 0 auto;
  padding: 0px 15px 8px;
}

.storeValueAll p {
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  margin: 22px 0 0;
}
@media screen and (max-width:767px){
  .bg_black{
    max-height: 320px;
    min-height: 320px;
    width: 100%;
  }
  #gaugCont{
    width: 60%;
  }
  span{
    font-size: 30px;
  }
}
</style>
